import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import SplashPage from './SplashPage';
import Gallery from './Gallery';
import AboutPage from './AboutPage';
import Header from './Header';
import NotFound from './NotFound';
import { loadProjects } from './utils/dataLoader';
import { initViewportHeight } from './utils/viewportHeight';
import { AuthProvider } from './utils/AuthContext';
import LoginPage from './components/rental/LoginPage';
import RentalDashboard from './components/rental/RentalDashboard';
import CreateAgreement from './components/rental/CreateAgreement';
import MakeReservation from './components/rental/MakeReservation';
import ProtectedRoute from './components/ProtectedRoute';
import './App.scss';
import { HelmetProvider } from 'react-helmet-async';
import { config, routes, getRentalUrl } from './utils/routingConfig';

const HeaderWrapper = ({ projects, currentProject, onProjectSelect }) => {
  const location = useLocation();
  const hostname = window.location.hostname;
  const isRentalSubdomain = hostname.startsWith('rent.');

  // Don't render header for rental subdomain at all
  if (isRentalSubdomain) {
    return null;
  }

  const isHomePage = location.pathname === '/';
  if (isHomePage) {
    return null;
  }

  if (!projects || projects.length === 0 || !currentProject) {
    return null;
  }

  return (
    <Header
      projects={projects}
      project={currentProject}
      onProjectSelect={onProjectSelect}
    />
  );
};

function App() {
  const [currentProject, setCurrentProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const isRentalSubdomain = hostname.startsWith('rent.');

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Elodie Carstensen",
    "url": "https://www.elodiecarstensen.com",
    "jobTitle": "Fashion Designer & Artist",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Berlin",
      "addressCountry": "DE"
    },
    "description": "Berlin-based artist and designer creating immersive fashion experiences through collections, installations, and performances.",
    "image": "https://www.elodiecarstensen.com/assets/images/social-preview.jpg",
    "sameAs": [
      "https://www.instagram.com/elodiecarstensen/",
      // Add other social media URLs
    ]
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const projectsData = await loadProjects();
      setProjects(projectsData);
      // Set the first project as current if none is selected
      if (!currentProject && projectsData.length > 0) {
        setCurrentProject(projectsData[0]);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [currentProject]);

  useEffect(() => {
    if (currentProject) {
      document.documentElement.style.setProperty('--project-theme-color', currentProject.themeColor);
    }
  }, [currentProject]);

  const handleProjectSelect = (projectId) => {
    const selected = projects.find(p => p.id === projectId);
    setCurrentProject(selected);
  };

  const showHeader = location.pathname !== '/';

  useEffect(() => {
    initViewportHeight();
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/work/')) {
      const projectId = location.pathname.split('/work/')[1];
      if (projectId) {
        navigate(`/gallery/${projectId}`, { replace: true });
      } else {
        navigate('/gallery', { replace: true });
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    console.log('Current hostname:', hostname);
    console.log('Is rental subdomain:', isRentalSubdomain);
    console.log('Current path:', location.pathname);
  }, [hostname, isRentalSubdomain, location.pathname]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <div className="app-container" style={{ backgroundColor: currentProject?.themeColor || '#091115' }}>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
          <HeaderWrapper
            projects={projects}
            currentProject={currentProject}
            onProjectSelect={handleProjectSelect}
          />
          <main className={`main-content ${showHeader ? 'with-header' : ''}`}>
            <Routes>
              {config.isRentalSubdomain ? (
              // Rental subdomain routes
                <>
                  <Route path={routes.rental.login} element={<LoginPage />} />
                  <Route
                    path={routes.rental.home}
                    element={
                      <ProtectedRoute>
                        <RentalDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${routes.rental.createAgreement}/:bookingId`}
                    element={
                      <ProtectedRoute>
                        <CreateAgreement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routes.rental.makeReservation}
                    element={
                      <ProtectedRoute>
                        <MakeReservation />
                      </ProtectedRoute>
                    }
                  />
                  {/* Catch-all redirect for rental subdomain */}
                  <Route path="*" element={<Navigate to={routes.rental.home} replace />} />
                </>
              ) : (
                  // Main domain routes
                <>
                    <Route path={routes.main.home} element={<SplashPage onEnter={() => handleProjectSelect(projects[0]?.id)} />} />
                    <Route path={routes.main.rental} element={<Navigate to={getRentalUrl()} replace />} />
                    <Route path="/rental/*" element={<Navigate to={getRentalUrl()} replace />} />
                  <Route
                      path="/gallery"
                      element={projects.length > 0 ? <Navigate to={`/gallery/${projects[0]?.id}`} replace /> : null}
                  />
                  <Route
                      path="/gallery/:projectId"
                      element={
                        <Gallery
                          projects={projects}
                          currentProject={currentProject}
                          setCurrentProject={setCurrentProject}
                        />
                      }
                    />
                    <Route
                      path="/about"
                      element={
                        <AboutPage data={projects.find(p => p.id === 'about')} />
                      }
                  />
                </>
              )}

              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;

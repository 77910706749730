import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createReservation, fetchGarments, createCustomer, fetchCustomers, fetchBookings, updateCustomer, deleteCustomer } from '../../utils/airtable';
import { useAuth } from '../../utils/AuthContext';
import '../../styles/common.scss';
import './MakeReservation.scss';
import { getRoute } from '../../utils/routingConfig';

export const discountOptions = [
  { label: 'None (0%)', value: 0 },
  { label: 'Semi-Commercial (-30%)', value: 0.3 },
  { label: 'Artists (-75%)', value: 0.75 },
  { label: 'Full (-100%)', value: 1 }
];

const calculateRentalPrice = (weeks, weeklyRate) => {
  if (!weeks || !weeklyRate) return 0;
  // Round to 2 decimal places
  return Math.round(weeks * weeklyRate * 100) / 100;
};

const MakeReservation = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const dataFetchAttemptedRef = useRef(false);
  const fetchedDataRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  // Helper function to get default dates
  const getDefaultDates = () => {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 7);

    // Format dates as YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(today),
      endDate: formatDate(nextWeek),
      weeks: 1
    };
  };

  const defaultDates = getDefaultDates();

  const [formData, setFormData] = useState({
    customerName: '',
    customerEmail: '',
    customerAddress: '',
    startDate: defaultDates.startDate,
    endDate: defaultDates.endDate,
    weeks: defaultDates.weeks,
    discount: 'None (0%)'
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [customers, setCustomers] = useState([]);
  const [selectedGarments, setSelectedGarments] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [garmentsByCollection, setGarmentsByCollection] = useState({});
  const [allBookings, setAllBookings] = useState([]);
  const [unavailableGarments, setUnavailableGarments] = useState(new Set());
  const [reservationError, setReservationError] = useState('');
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [editedCustomerData, setEditedCustomerData] = useState(null);

  // Format price with comma and proper euro symbol placement
  const formatPrice = (price) => {
    const formattedPrice = price.toFixed(2).replace('.', ',');
    return formattedPrice + '€';
  };

  // Calculate total price with discount
  const calculateTotalPrice = (basePrice, discount) => {
    const discountRate = discountOptions.find(option => option.label === discount)?.value || 0;
    const discountedPrice = basePrice * (1 - discountRate);
    // Round to 2 decimal places to avoid floating point precision issues
    return Math.round(Math.max(0, discountedPrice) * 100) / 100;
  };

  // Handle discount change
  const handleDiscountChange = (e) => {
    setFormData(prev => ({
      ...prev,
      discount: e.target.value
    }));
  };

  // Memoize total price calculation
  const calculateTotalPriceForSelection = useMemo(() => {
    if (!formData.startDate || selectedGarments.length === 0) return 0;

    return selectedGarments.reduce((sum, garmentId) => {
      const garment = Object.values(garmentsByCollection)
        .flat()
        .find(g => g.id === garmentId);
      return sum + calculateRentalPrice(formData.weeks, garment?.rental || 0);
    }, 0);
  }, [formData.startDate, formData.weeks, selectedGarments, garmentsByCollection]);

  // Update total price when selection changes
  useEffect(() => {
    setTotalPrice(calculateTotalPriceForSelection);
  }, [calculateTotalPriceForSelection]);

  // Availability check callback
  const checkAvailability = useCallback(() => {
    if (!formData.startDate || !formData.endDate || !allBookings.length) {
      return;
    }

    const start = new Date(formData.startDate);
    const end = new Date(formData.endDate);
    const unavailable = new Set();

    allBookings.forEach(booking => {
      if (!booking['Start Date'] || !booking['End Date'] || !booking.Garment || booking.status === 'Cancelled') return;

      const bookingStart = new Date(booking['Start Date']);
      const bookingEnd = new Date(booking['End Date']);

      if (start <= bookingEnd && end >= bookingStart) {
        const garmentIds = Array.isArray(booking.Garment) ? booking.Garment : [booking.Garment];
        garmentIds.forEach(garmentId => unavailable.add(garmentId));
      }
    });

    setUnavailableGarments(unavailable);
  }, [formData.startDate, formData.endDate, allBookings]);

  // Fetch data effect
  useEffect(() => {
    let isCurrentMount = true;
    console.log('Effect running, fetch attempted:', dataFetchAttemptedRef.current);

    const loadData = async () => {
      // If we have fetched data stored, use it
      if (fetchedDataRef.current) {
        console.log('Using stored data from previous fetch');
        const { garmentsData, customersData, bookingsData } = fetchedDataRef.current;
        const groupedGarments = garmentsData.reduce((acc, garment) => {
          const collection = garment.Collection || 'Uncategorized';
          if (!acc[collection]) {
            acc[collection] = [];
          }
          acc[collection].push(garment);
          return acc;
        }, {});

        setGarmentsByCollection(groupedGarments);
        setCustomers(customersData);
        setAllBookings(bookingsData);
        setFetchError(null);
        setIsLoading(false);
        return;
      }

      // Skip if we've already attempted a fetch but don't have stored data
      if (dataFetchAttemptedRef.current) {
        console.log('Data fetch already attempted but no stored data, retrying fetch');
      }

      try {
        console.log('Starting data fetch');
        dataFetchAttemptedRef.current = true;

        const [garmentsData, customersData, bookingsData] = await Promise.all([
          fetchGarments(),
          fetchCustomers(),
          fetchBookings(false)
        ]);

        // Store the fetched data
        fetchedDataRef.current = {
          garmentsData,
          customersData,
          bookingsData
        };

        // Only update state if this effect is still current
        if (isCurrentMount) {
          console.log('Data fetch completed, updating state');
          const groupedGarments = garmentsData.reduce((acc, garment) => {
            const collection = garment.Collection || 'Uncategorized';
            if (!acc[collection]) {
              acc[collection] = [];
            }
            acc[collection].push(garment);
            return acc;
          }, {});

          setGarmentsByCollection(groupedGarments);
          setCustomers(customersData);
          setAllBookings(bookingsData);
          setFetchError(null);
          setIsLoading(false);
          console.log('State updates complete');
        } else {
          console.log('Mount is no longer current, data stored for next mount');
        }
      } catch (err) {
        console.error('Error loading data:', err);
        if (isCurrentMount) {
          setFetchError(err.message || 'Failed to load data');
          setIsLoading(false);
        }
      }
    };

    loadData();

    return () => {
      console.log('Cleanup: marking current mount as inactive');
      isCurrentMount = false;
    };
  }, []); // Empty dependency array ensures single execution

  // Check availability effect
  useEffect(() => {
    if (allBookings.length > 0) {
      checkAvailability();
    }
  }, [checkAvailability, allBookings.length]);

  // Garment availability check
  const isGarmentAvailable = useCallback((garmentId) => {
    return !unavailableGarments.has(garmentId);
  }, [unavailableGarments]);

  // Show loading state
  if (isLoading) {
    return <div className="loading-container">Loading...</div>;
  }

  // Show error state
  if (fetchError) {
    return (
      <div className="error-container">
        <h2>Error loading data</h2>
        <p>{fetchError}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  const getGarmentBookings = (garment) => {
    if (!garment.Bookings || garment.Bookings.length === 0) return null;

    console.log('Garment bookings:', garment.Bookings);

    return garment.Bookings.map(booking => ({
      startDate: new Date(booking['Start Date']).toLocaleDateString(),
      endDate: new Date(booking['End Date']).toLocaleDateString()
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return 'Email is required';
    }
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Remove error state when user starts typing
    const field = e.target.closest('.form-group');
    if (field && field.classList.contains('error')) {
      field.classList.remove('error');
    }
  };

  const handleCustomerSelect = (e) => {
    const customerId = e.target.value;
    if (customerId === 'new') {
      setSelectedCustomer(null);
      setFormData(prev => ({
        ...prev,
        customerName: '',
        customerEmail: '',
        customerAddress: ''
      }));
      setIsEditingCustomer(false);
      setEditedCustomerData(null);
    } else {
      const customer = customers.find(c => c.id === customerId);
      setSelectedCustomer(customer);
      setFormData(prev => ({
        ...prev,
        customerName: customer.Name || '',
        customerEmail: customer.Email || '',
        customerAddress: customer.Address || ''
      }));
      setEditedCustomerData(null);
      setIsEditingCustomer(false);
    }
  };

  const handleEditCustomer = () => {
    setIsEditingCustomer(true);
    setEditedCustomerData({
      Name: selectedCustomer.Name || '',
      Email: selectedCustomer.Email || '',
      Address: selectedCustomer.Address || ''
    });
    setFormErrors({}); // Clear any existing errors
  };

  const handleCancelEdit = () => {
    setIsEditingCustomer(false);
    setEditedCustomerData(null);
    setFormErrors({});
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedCustomerData(prev => ({
      ...prev,
      [name]: value
    }));

    // Remove error state when user starts typing
    const field = e.target.closest('.form-group');
    if (field && field.classList.contains('error')) {
      field.classList.remove('error');
    }
  };

  const validateEditCustomerForm = () => {
    const errors = {};
    const fields = document.querySelectorAll('.form-group');

    // Remove all existing error states
    fields.forEach(field => {
      field.classList.remove('error');
    });

    if (!editedCustomerData?.Name?.trim()) {
      errors.Name = 'Customer name is required';
      document.querySelector('[name="Name"]')?.closest('.form-group')?.classList.add('error');
    }

    if (!editedCustomerData?.Email?.trim()) {
      errors.Email = 'Email is required';
      document.querySelector('[name="Email"]')?.closest('.form-group')?.classList.add('error');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedCustomerData.Email)) {
      errors.Email = 'Please enter a valid email address';
      document.querySelector('[name="Email"]')?.closest('.form-group')?.classList.add('error');
    }

    if (!editedCustomerData?.Address?.trim()) {
      errors.Address = 'Address is required';
      document.querySelector('[name="Address"]')?.closest('.form-group')?.classList.add('error');
    }

    return errors;
  };

  const handleUpdateCustomer = async () => {
    // Validate the edited data
    const errors = validateEditCustomerForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      // Update customer in Airtable
      const updatedCustomer = await updateCustomer(selectedCustomer.id, editedCustomerData);

      // Update local state
      setCustomers(prev => prev.map(c =>
        c.id === selectedCustomer.id ? { ...c, ...updatedCustomer.fields } : c
      ));
      setSelectedCustomer({ ...selectedCustomer, ...updatedCustomer.fields });
      setIsEditingCustomer(false);
      setEditedCustomerData(null);
      setFormErrors({});
      setError('Customer updated successfully!');
      setTimeout(() => setError(''), 3000);
    } catch (err) {
      setError(err.message || 'Failed to update customer');
    }
  };

  const handleDeleteCustomer = async () => {
    if (window.confirm('Are you sure you want to delete this customer? This action cannot be undone.')) {
      try {
        await deleteCustomer(selectedCustomer.id);
        setCustomers(prev => prev.filter(c => c.id !== selectedCustomer.id));
        setSelectedCustomer(null);
        setFormData(prev => ({
          ...prev,
          customerName: '',
          customerEmail: '',
          customerAddress: ''
        }));
        setError('Customer deleted successfully!');
        setTimeout(() => setError(''), 3000);
      } catch (err) {
        setError(err.message || 'Failed to delete customer');
      }
    }
  };

  const handleGarmentSelection = (garmentId) => {
    if (!isGarmentAvailable(garmentId)) return;

    setSelectedGarments(prev =>
      prev.includes(garmentId)
        ? prev.filter(id => id !== garmentId)
        : [...prev, garmentId]
    );
  };

  const validateReservation = () => {
    const errors = {};
    if (!selectedCustomer) {
      errors.customer = 'Please select or create a customer';
    }
    if (!formData.startDate) {
      errors.startDate = 'Start date is required';
    }
    if (!formData.endDate) {
      errors.endDate = 'End date is required';
    }
    if (selectedGarments.length === 0) {
      errors.garments = 'Please select at least one garment';
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setReservationError('');
    setFormErrors({});

    // Validate reservation
    const errors = validateReservation();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      // Get the discount rate value from the selected discount option
      const discountRate = discountOptions.find(
        option => option.label === formData.discount
      )?.value || 0;

      const reservationData = {
        'Customer Name': [selectedCustomer.id],
        'Start Date': formData.startDate,
        'End Date': formData.endDate,
        'Garment': selectedGarments,
        'Rental periods': formData.weeks,
        'Discount Rate': discountRate
      };

      const newReservation = await createReservation(reservationData);

      // Navigate with success message
      navigate('/rental', {
        state: {
          notification: {
            type: 'success',
            message: `Reservation created successfully for ${selectedCustomer.Name}`
          },
          highlightReservation: newReservation.id
        }
      });
    } catch (err) {
      setReservationError(err.message || 'Failed to create reservation');
      window.scrollTo(0, 0);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(getRoute('home'));
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    navigate(getRoute('login'));
  };

  const validateCustomerForm = () => {
    const errors = {};
    const fields = document.querySelectorAll('.form-group');

    // Remove all existing error states
    fields.forEach(field => {
      field.classList.remove('error');
    });

    if (!formData.customerName.trim()) {
      errors.customerName = 'Customer name is required';
      document.querySelector('[name="customerName"]').closest('.form-group').classList.add('error');
    }

    if (!formData.customerEmail.trim()) {
      errors.customerEmail = 'Email is required';
      document.querySelector('[name="customerEmail"]').closest('.form-group').classList.add('error');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.customerEmail)) {
      errors.customerEmail = 'Please enter a valid email address';
      document.querySelector('[name="customerEmail"]').closest('.form-group').classList.add('error');
    }

    if (!formData.customerAddress.trim()) {
      errors.customerAddress = 'Address is required';
      document.querySelector('[name="customerAddress"]').closest('.form-group').classList.add('error');
    }

    return errors;
  };

  const handleCreateCustomer = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setFormErrors({});

    // Validate form
    const errors = validateCustomerForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      const customerRecord = await createCustomer({
        'Name': formData.customerName,
        'Email': formData.customerEmail,
        'Address': formData.customerAddress
      });

      // Add the new customer to the customers list
      setCustomers(prev => [...prev, {
        id: customerRecord.id,
        ...customerRecord.fields
      }]);

      // Select the newly created customer
      setSelectedCustomer({
        id: customerRecord.id,
        ...customerRecord.fields
      });

      // Show success message
      setError('Customer created successfully!');
      setTimeout(() => setError(''), 3000);
    } catch (err) {
      setError(err.message || 'Failed to create customer');
    } finally {
      setLoading(false);
    }
  };

  const getLatestBookingDate = (garment) => {
    if (!garment['All Reservations'] || garment['All Reservations'].length === 0) {
      return null;
    }

    const bookings = garment['All Reservations']
      .map(booking => booking['End Date'])
      .filter(date => date && new Date(date) >= new Date())
      .sort((a, b) => new Date(b) - new Date(a));

    return bookings[0] || null;
  };

  const GarmentCard = React.memo(({ garment, collection }) => {
    const isAvailable = !unavailableGarments.has(garment.id);
    const [bookedUntil, setBookedUntil] = useState(null);

    useEffect(() => {
      if (!isAvailable) {
        // Find the latest booking for this garment
        const garmentBookings = allBookings
          .filter(b => b.garment === garment.id && b.endDate && new Date(b.endDate) >= new Date())
          .sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

        if (garmentBookings.length > 0) {
          setBookedUntil(new Date(garmentBookings[0].endDate).toLocaleDateString());
        }
      }
    }, [garment.id, isAvailable]);

    const isSelected = selectedGarments.includes(garment.id);

    return (
      <div
        className={`garment-card ${isSelected ? 'selected' : ''} ${!isAvailable ? 'unavailable' : ''}`}
        onClick={() => isAvailable && handleGarmentSelection(garment.id)}
      >
        <div
          className="garment-image"
          style={{
            backgroundImage: garment.Image
              ? `url(${Array.isArray(garment.Image) ? garment.Image[0].url : garment.Image.url})`
              : 'none'
          }}
        />
        <div className="garment-info">
          <div className="garment-title">
            <h3>{garment['Item Name'] || 'Unnamed Garment'}</h3>
            <div className="garment-price">€{formatPrice(garment.rental)}/week</div>
          </div>

          {!isAvailable && (
            <div className="availability-info">
              <span className="unavailable-label">
                Not available for selected dates
              </span>
              {bookedUntil && (
                <span className="booked-until">
                  Booked up until: {bookedUntil}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="garment-badge">{collection}</div>
      </div>
    );
  });

  // Determine if form can be submitted
  const canSubmit =
    selectedCustomer?.id &&
    formData.startDate &&
    formData.endDate &&
    selectedGarments.length > 0 &&
    !loading;

  const handleDateChange = (type, value) => {
    if (type === 'start') {
      // If new start date is after current end date, update end date
      if (formData.endDate && value > formData.endDate) {
        setFormData(prev => ({
          ...prev,
          startDate: value,
          endDate: value
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          startDate: value
        }));
      }
    } else {
      // Don't allow end date to be before start date
      if (formData.startDate && value < formData.startDate) {
        return;
      }
      setFormData(prev => ({
        ...prev,
        endDate: value
      }));
    }
  };

  const handleWeeksChange = (change) => {
    const newWeeks = Math.max(1, formData.weeks + change);
    const startDate = new Date(formData.startDate);
    const newEndDate = new Date(startDate);
    newEndDate.setDate(startDate.getDate() + (newWeeks * 7));

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setFormData(prev => ({
      ...prev,
      weeks: newWeeks,
      endDate: formatDate(newEndDate)
    }));
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    const startDate = new Date(newStartDate);
    const newEndDate = new Date(startDate);
    newEndDate.setDate(startDate.getDate() + (formData.weeks * 7));

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setFormData(prev => ({
      ...prev,
      startDate: newStartDate,
      endDate: formatDate(newEndDate)
    }));
  };

  return (
    <div className="make-reservation">
      <div className="reservation-header">
        <div className="header-left">
          <button className="back-button" onClick={() => navigate('/rental')}>
            ← Back
          </button>
          <h1>MAKE A RESERVATION</h1>
        </div>
        <button className="btn btn-outline" onClick={handleLogout}>
          LOGOUT
        </button>
      </div>

      <div className="reservation-form">
        <div className="form-section">
          <h2>CUSTOMER INFORMATION</h2>
          {formErrors.customer && (
            <div className="error-message section-error">{formErrors.customer}</div>
          )}
          <div className="form-row">
            <div className="form-group">
              <label>SELECT CUSTOMER</label>
              <select
                value={selectedCustomer?.id || 'new'}
                onChange={handleCustomerSelect}
              >
                <option value="new">+ Add New Customer</option>
                {customers.map(customer => (
                  <option key={customer.id} value={customer.id}>
                    {customer.Name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {selectedCustomer && !isEditingCustomer && (
            <div className="customer-details">
              <div className="customer-info">
                <div className="info-row">
                  <label>Name:</label>
                  <span>{selectedCustomer.Name}</span>
                </div>
                <div className="info-row">
                  <label>Email:</label>
                  <span>{selectedCustomer.Email}</span>
                </div>
                <div className="info-row">
                  <label>Address:</label>
                  <span>{selectedCustomer.Address}</span>
                </div>
              </div>
              <div className="customer-actions">
                <button className="btn btn-outline" onClick={handleEditCustomer}>
                  Edit Customer
                </button>
                <button className="btn btn-outline delete" onClick={handleDeleteCustomer}>
                  Delete Customer
                </button>
              </div>
            </div>
          )}

          {selectedCustomer && isEditingCustomer && (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label>CUSTOMER NAME</label>
                  <input
                    type="text"
                    name="Name"
                    value={editedCustomerData.Name}
                    onChange={handleEditChange}
                    className={formErrors.customerName ? 'error' : ''}
                  />
                  {formErrors.customerName && (
                    <div className="error-message">{formErrors.customerName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>EMAIL</label>
                  <input
                    type="email"
                    name="Email"
                    value={editedCustomerData.Email}
                    onChange={handleEditChange}
                    className={formErrors.customerEmail ? 'error' : ''}
                  />
                  {formErrors.customerEmail && (
                    <div className="error-message">{formErrors.customerEmail}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>ADDRESS</label>
                  <textarea
                    name="Address"
                    value={editedCustomerData.Address}
                    onChange={handleEditChange}
                    className={formErrors.customerAddress ? 'error' : ''}
                  />
                  {formErrors.customerAddress && (
                    <div className="error-message">{formErrors.customerAddress}</div>
                  )}
                </div>
              </div>
              <div className="edit-actions">
                <button className="btn btn-primary" onClick={handleUpdateCustomer}>
                  Save Changes
                </button>
                <button className="btn btn-outline" onClick={handleCancelEdit}>
                  Cancel
                </button>
              </div>
            </>
          )}

          {!selectedCustomer && (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label>CUSTOMER NAME</label>
                  <input
                    type="text"
                    name="customerName"
                    value={formData.customerName}
                    onChange={handleChange}
                    className={formErrors.customerName ? 'error' : ''}
                    required
                  />
                  {formErrors.customerName && (
                    <div className="error-message">{formErrors.customerName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label>EMAIL</label>
                  <input
                    type="email"
                    name="customerEmail"
                    value={formData.customerEmail}
                    onChange={handleChange}
                    className={formErrors.customerEmail ? 'error' : ''}
                    required
                  />
                  {formErrors.customerEmail && (
                    <div className="error-message">{formErrors.customerEmail}</div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <label>ADDRESS</label>
                  <textarea
                    name="customerAddress"
                    value={formData.customerAddress}
                    onChange={handleChange}
                    className={formErrors.customerAddress ? 'error' : ''}
                    required
                  />
                  {formErrors.customerAddress && (
                    <div className="error-message">{formErrors.customerAddress}</div>
                  )}
                </div>
              </div>
              <button
                className="btn btn-secondary"
                onClick={handleCreateCustomer}
                disabled={loading}
              >
                {loading ? 'CREATING...' : 'CREATE CUSTOMER'}
              </button>
              {error && <div className={`form-message ${error.includes('success') ? 'success' : 'error'}`}>{error}</div>}
            </>
          )}
        </div>

        <div className="reservation-period">
          <h2>RESERVATION PERIOD</h2>
          <div className="rental-dates">
            <div className="rental-start">
              <label>RENTAL START</label>
              <div className="input-wrapper">
                <input
                  type="date"
                  value={formData.startDate}
                  onChange={handleStartDateChange}
                  min={defaultDates.startDate}
                />
              </div>
            </div>
            <div className="rental-duration">
              <label>RENTAL DURATION</label>
              <div className="weeks-selector">
                <button onClick={() => handleWeeksChange(-1)} disabled={formData.weeks <= 1}>−</button>
                <span>{formData.weeks} WEEKS</span>
                <button onClick={() => handleWeeksChange(1)}>+</button>
                <div className="until-date">Until: {formData.endDate}</div>
              </div>
            </div>
            <div className="rental-discount">
              <label>DISCOUNT</label>
              <div className="input-wrapper">
                <select
                  value={formData.discount}
                  onChange={handleDiscountChange}
                >
                  {discountOptions.map(option => (
                    <option key={option.label} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>SELECT GARMENTS</h2>
          {formErrors.garments && (
            <div className="error-message section-error">{formErrors.garments}</div>
          )}
          <div className="garment-grid">
            {Object.entries(garmentsByCollection).map(([collection, garments]) => (
              <React.Fragment key={collection}>
                <h3 className="collection-name">{collection}</h3>
                {garments.map(garment => {
                  const isAvailable = !unavailableGarments.has(garment.id);
                  return (
                    <div
                      key={garment.id}
                      className={`garment-card ${selectedGarments.includes(garment.id) ? 'selected' : ''} ${!isAvailable ? 'unavailable' : ''}`}
                      onClick={() => isAvailable && handleGarmentSelection(garment.id)}
                    >
                      <div
                        className="garment-image"
                        style={{
                          backgroundImage: garment.Image?.[0]?.url
                            ? `url(${garment.Image[0].url})`
                            : 'none'
                        }}
                      />
                      <div className="garment-info">
                        <h4 className="garment-name">{garment['Item Name']}</h4>
                        <p className="garment-price">€{formatPrice(garment.rental)}/week</p>
                        {!isAvailable && (
                          <div className="availability-info">
                            <span className="unavailable-label">Not available for selected dates</span>
                            {(() => {
                              // Find the latest booking that overlaps with or comes after the selected dates
                              const relevantBookings = allBookings
                                .filter(b => b.garment === garment.id && b.endDate && new Date(b.endDate) >= new Date(formData.startDate))
                                .sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

                              if (relevantBookings.length > 0) {
                                const nextAvailableDate = new Date(relevantBookings[0].endDate);
                                nextAvailableDate.setDate(nextAvailableDate.getDate() + 1); // Add one day to the end date

                                // Format date as DD/MM/YYYY
                                const formattedDate = nextAvailableDate.toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric'
                                });

                                return (
                                  <div className="availability-info">
                                    <span className="unavailable-label">Not available for selected dates</span>
                                    <span className="available-from">
                                      AVAILABLE FROM: {formattedDate}
                                    </span>
                                  </div>
                                );
                              }
                              return null;
                            })()}
                          </div>
                        )}
                      </div>
                      <div className="garment-badge">{collection}</div>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="sticky-footer">
        <div className="total-price">
          <span className="price-label">TOTAL RENTAL PRICE</span>
          {formData.discount !== 'None (0%)' && (
            <span className="original-price">€{formatPrice(totalPrice)}</span>
          )}
          <span className="final-price">€{formatPrice(calculateTotalPrice(totalPrice, formData.discount))}</span>
        </div>
        {reservationError && (
          <div className="error-message">{reservationError}</div>
        )}
        <button
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={!canSubmit}
        >
          {loading ? 'Creating...' : 'Make Reservation'}
        </button>
      </div>
    </div>
  );
};

export default MakeReservation;
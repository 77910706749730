import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchAllBookings, deleteBooking } from '../../utils/airtable';
import { createRentalAgreement } from '../../utils/docsautomator';
import { useAuth } from '../../utils/AuthContext';
import '../../styles/common.scss';
import './RentalDashboard.scss';
import { getRoute } from '../../utils/routingConfig';

// Static flags to track data fetching and requests across remounts
let isInitialFetchDone = false;
let pendingFetchPromise = null;
let cachedBookings = null;
let isProcessingResult = false;

const RentalDashboard = () => {
  console.log('RentalDashboard render', { isInitialFetchDone, hasCachedData: !!cachedBookings });
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const highlightId = location.state?.highlightReservation;
  const [bookings, setBookings] = useState(() => cachedBookings || []);
  const [loading, setLoading] = useState(!cachedBookings);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [includeExpired, setIncludeExpired] = useState(false);
  const [creatingAgreements, setCreatingAgreements] = useState(new Set());
  const [deletingBookings, setDeletingBookings] = useState(new Set());
  const highlightRef = useRef(null);
  const refreshTimeoutRef = useRef(null);
  const mountCountRef = useRef(0);

  // Create a stable reference to the refresh function
  const refreshBookings = useCallback(async (force = false) => {
    console.log('refreshBookings called:', {
      force,
      isInitialFetchDone,
      hasCachedData: !!cachedBookings,
      mountCount: mountCountRef.current
    });

    // Use cached data if available and not forcing refresh
    if (!force && cachedBookings) {
      console.log('Using cached data');
      setBookings(cachedBookings);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      let fetchPromise;
      // Create new promise if none exists
      if (!pendingFetchPromise) {
        console.log('Starting new Airtable fetch...');
        fetchPromise = fetchAllBookings();
        pendingFetchPromise = fetchPromise;
      } else {
        console.log('Using existing fetch promise');
        fetchPromise = pendingFetchPromise;
      }

      const allBookings = await fetchPromise;

      // Only process result once
      if (!isProcessingResult) {
        isProcessingResult = true;
        console.log('Fetch completed:', {
          bookingsCount: allBookings?.length || 0,
          firstBookingId: allBookings?.[0]?.id
        });

        // Only update state if we got new data
        if (allBookings?.length >= 0) {
          cachedBookings = allBookings;
          setBookings(allBookings);
          isInitialFetchDone = true;
          console.log('State and cache updated with fetched data');
        }
        isProcessingResult = false;
      }
    } catch (err) {
      console.error('Error fetching bookings:', err);
      setError('Failed to load bookings');
      cachedBookings = null;
    } finally {
      pendingFetchPromise = null;
      setLoading(false);
    }
  }, []);

  // Memoize filtered bookings
  const filteredBookings = React.useMemo(() => {
    console.log('Calculating filtered bookings:', {
      total: bookings.length,
      includeExpired,
      mountCount: mountCountRef.current
    });

    if (!bookings.length) return [];

    return bookings.filter(booking => {
      const endDate = new Date(booking['End Date']);
      const isExpired = endDate < new Date();
      return includeExpired ? true : !isExpired;
    });
  }, [bookings, includeExpired]);

  // Debounced refresh for updates
  const debouncedRefresh = useCallback(() => {
    console.log('Debounced refresh requested');
    if (refreshTimeoutRef.current) {
      console.log('Clearing existing refresh timeout');
      clearTimeout(refreshTimeoutRef.current);
    }
    refreshTimeoutRef.current = setTimeout(() => {
      console.log('Executing debounced refresh');
      refreshBookings(true); // Force refresh for updates
    }, 300);
  }, [refreshBookings]);

  // Initial data fetch
  useEffect(() => {
    mountCountRef.current += 1;
    console.log('Mount effect running:', {
      mountCount: mountCountRef.current,
      isInitialFetchDone,
      hasPendingFetch: !!pendingFetchPromise
    });

    refreshBookings();

    return () => {
      console.log('Cleanup: clearing timeouts, mount count:', mountCountRef.current);
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [refreshBookings]);

  // Success message cleanup
  useEffect(() => {
    if (!success) return;
    console.log('Setting success message timeout');
    const timer = setTimeout(() => {
      console.log('Clearing success message');
      setSuccess('');
    }, 3000);
    return () => clearTimeout(timer);
  }, [success]);

  // Scroll to highlighted booking
  useEffect(() => {
    if (highlightId && highlightRef.current) {
      console.log('Scrolling to highlighted booking:', highlightId);
      highlightRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [highlightId]);

  const handleCreateAgreement = async (booking) => {
    console.log('Creating agreement for booking:', booking.id);
    try {
      setCreatingAgreements(prev => new Set([...prev, booking.id]));
      setError('');
      const agreement = await createRentalAgreement(booking.id);

      if (agreement?.pdfUrl) {
        console.log('Agreement created successfully:', agreement.pdfUrl);
        setSuccess('Agreement created successfully!');
        debouncedRefresh();
      } else {
        throw new Error('No PDF URL in response');
      }
    } catch (err) {
      console.error('Error creating agreement:', err);
      setError('Failed to create agreement');
    } finally {
      setCreatingAgreements(prev => {
        const next = new Set(prev);
        next.delete(booking.id);
        return next;
      });
    }
  };

  const handleDeleteBooking = async (booking) => {
    console.log('Attempting to delete booking:', booking.id);
    const customerName = booking['Name (Customer)'];
    const startDate = new Date(booking['Start Date']).toLocaleDateString();

    if (window.confirm(`Are you sure you want to delete the booking for ${customerName} starting ${startDate}?`)) {
      try {
        setDeletingBookings(prev => new Set([...prev, booking.id]));
        setError('');

        await deleteBooking(booking.id);
        console.log('Booking deleted successfully:', booking.id);
        setSuccess('Booking deleted successfully');
        debouncedRefresh();
      } catch (err) {
        console.error('Error deleting booking:', err);
        setError('Failed to delete booking');
      } finally {
        setDeletingBookings(prev => {
          const next = new Set(prev);
          next.delete(booking.id);
          return next;
        });
      }
    } else {
      console.log('Booking deletion cancelled by user');
    }
  };

  // Format price with comma and proper euro symbol placement
  const formatPrice = (price) => {
    if (!price) return '0,00€';
    const formattedPrice = Number(price).toFixed(2).replace('.', ',');
    return formattedPrice + '€';
  };

  const renderBookingCard = (booking) => {
    const garments = booking.Garments || booking.garments || [];
    const isCreatingAgreement = creatingAgreements.has(booking.id);
    const isDeletingBooking = deletingBookings.has(booking.id);
    const hasDiscount = booking['Discount Rate'] && booking['Discount Rate'] !== 'None (0%)';
    const isHighlighted = booking.id === highlightId;

    return (
      <div
        ref={isHighlighted ? highlightRef : null}
        key={booking.id}
        className={`booking-card ${isHighlighted ? 'highlight' : ''}`}
      >
        <div className="booking-header">
          {isHighlighted && <span className="new-tag">New</span>}
          <div className="booking-customer">
            <h3>{booking['Name (Customer)']?.[0]}</h3>
            <div className="customer-meta">
              <span className="customer-address">{booking['Address (Customer)']?.[0]}</span>
            </div>
          </div>
          <div className="booking-info">
            <div className="booking-dates">
              <div className="date-range">
                <span>{new Date(booking['Start Date']).toLocaleDateString('en-GB')}</span>
                <span>→</span>
                <span>{new Date(booking['End Date']).toLocaleDateString('en-GB')}</span>
              </div>
              <div className="rental-periods">
                {booking['Rental periods']} {booking['Rental periods'] === 1 ? 'week' : 'weeks'}
              </div>
            </div>
            <div className="booking-price">
              {hasDiscount && (
                <>
                  <span className="original-price">{formatPrice(booking.SUBTOTAL)}</span>
                  <span className="discount-label">{booking['Discount Rate']}</span>
                </>
              )}
              <span className="final-price">{formatPrice(booking.TOTAL)}</span>
            </div>
          </div>
        </div>
        <div className="booking-content">
          {garments.map((garment, index) => (
            <div key={index} className="garment-info">
              <div
                className="garment-image"
                style={{
                  backgroundImage: garment.Image?.[0]?.url
                    ? `url(${garment.Image[0].url})`
                    : 'none'
                }}
              />
              <div className="garment-details">
                <h4>{garment['Item Name']}</h4>
              </div>
            </div>
          ))}
        </div>
        <div className="booking-actions">
          {booking['Rental agreement']?.[0]?.url ? (
            <a
              href={booking['Rental agreement'][0].url}
              target="_blank"
              rel="noopener noreferrer"
              className="action-button download"
            >
              Download Agreement
            </a>
          ) : (
            <button
              className="action-button create"
              onClick={() => handleCreateAgreement(booking)}
              disabled={isCreatingAgreement}
            >
              {isCreatingAgreement ? 'Creating...' : 'Create Agreement'}
            </button>
          )}
          <button
            className="action-button delete"
            onClick={() => handleDeleteBooking(booking)}
            disabled={isDeletingBooking}
          >
            {isDeletingBooking ? 'Deleting...' : 'Delete Booking'}
          </button>
        </div>
      </div>
    );
  };

  const renderBookings = () => {
    if (filteredBookings.length === 0) {
      return (
        <div className="empty-state">
          <h2>No rentals found</h2>
          <p>There are currently no rental bookings in the system.</p>
        </div>
      );
    }

    return (
      <div className="bookings-list">
        {filteredBookings.map(renderBookingCard)}
      </div>
    );
  };

  if (loading) {
    return <div className="loading">Loading bookings...</div>;
  }

  return (
    <div className="rental-dashboard">
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}

      <div className="dashboard-header">
        <h1>RENTAL DASHBOARD</h1>
        <div className="header-actions">
          <button
            className="new-booking-button"
            onClick={() => navigate(getRoute('makeReservation'))}
          >
            New Booking
          </button>
          <button
            className="btn btn-outline"
            onClick={() => {
              logout();
              navigate(getRoute('login'));
            }}
          >
            Logout
          </button>
        </div>
      </div>

      <div className="dashboard-container">
        {renderBookings()}

        <div className="show-expired-container">
          <button
            className="show-expired-button"
            onClick={() => setIncludeExpired(!includeExpired)}
          >
            {includeExpired ? 'Hide Expired Rentals' : 'Show Expired Rentals'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentalDashboard;
import { discountOptions } from '../components/rental/MakeReservation';

// Initialize Airtable only when needed
let base = null;

const initAirtable = () => {
  if (!base) {
    if (!process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN) {
      throw new Error('Airtable access token is missing');
    }
    if (!process.env.REACT_APP_AIRTABLE_BASE_ID) {
      throw new Error('Airtable base ID is missing');
    }

    const Airtable = require('airtable');
    base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN
    }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

    // Add debug logging
    console.log('Airtable Configuration:', {
      hasAccessToken: !!process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN,
      hasBaseId: !!process.env.REACT_APP_AIRTABLE_BASE_ID,
      accessTokenPrefix: process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN?.substring(0, 10) + '...',
    });
  }
  return base;
};

// Only keeping the BOOKINGS table ID as it's the only one used
const TABLES = {
  BOOKINGS: process.env.REACT_APP_AIRTABLE_BOOKINGS_TABLE_ID
};

export const fetchRentals = async () => {
  try {
    console.log('Fetching rentals...');
    const base = initAirtable();
    const records = await base('Rentals').select({
      view: 'Grid view'
    }).all();
    console.log(`Successfully fetched ${records.length} rentals`);
    return records.map(record => ({
      id: record.id,
      ...record.fields
    }));
  } catch (error) {
    console.error('Error fetching rentals:', error);
    throw error;
  }
};

export const createRental = async (rentalData) => {
  try {
    console.log('Creating rental with data:', rentalData);
    const base = initAirtable();
    const record = await base('Rentals').create([
      { fields: rentalData }
    ]);
    console.log('Successfully created rental:', record[0].id);
    return record[0];
  } catch (error) {
    console.error('Error creating rental:', error);
    throw error;
  }
};

export const fetchReservations = async () => {
  try {
    console.log('Fetching reservations...');
    const records = await base('Reservations').select({
      view: 'Grid view'
    }).all();
    console.log(`Successfully fetched ${records.length} reservations`);
    return records.map(record => ({
      id: record.id,
      ...record.fields
    }));
  } catch (error) {
    console.error('Error fetching reservations:', error);
    console.error('Error details:', {
      message: error.message,
      error: error.error,
      statusCode: error.statusCode
    });
    throw error;
  }
};

export const createReservation = async (reservationData) => {
  try {
    console.log('Incoming reservation data:', reservationData); // Debug log

    // Calculate rental periods from start and end date if weeks not provided
    let rentalPeriods = reservationData['Rental periods'];
    if (!rentalPeriods && reservationData['Start Date'] && reservationData['End Date']) {
      const start = new Date(reservationData['Start Date']);
      const end = new Date(reservationData['End Date']);
      const diffTime = Math.abs(end - start);
      rentalPeriods = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
    }

    // Convert discount rate value to label if it's a number
    let discountRate = reservationData['Discount Rate'];
    if (typeof discountRate === 'number') {
      const discountOption = discountOptions.find(option => option.value === discountRate);
      discountRate = discountOption ? discountOption.label : 'None (0%)';
    }

    // Format the data for Airtable
    const formattedData = {
      'Customer Name': reservationData['Customer Name'],
      'Start Date': reservationData['Start Date'],
      'Rental periods': rentalPeriods,
      'Discount Rate': discountRate,
      'Garment': reservationData['Garment']
    };

    console.log('Original data:', reservationData);
    console.log('Sending formatted data:', formattedData);

    const record = await base('tbl6DlYtYjYXpglcR').create([
      { fields: formattedData }
    ]);

    return record[0];
  } catch (error) {
    console.error('Error creating reservation:', error);
    console.error('Error details:', error);
    throw error;
  }
};

export const checkAvailability = async (startDate, endDate) => {
  try {
    console.log('Checking availability for dates:', { startDate, endDate });
    const records = await base('Reservations').select({
      filterByFormula: `AND(
        {Start Date} <= '${endDate}',
        {End Date} >= '${startDate}'
      )`
    }).all();
    const isAvailable = records.length === 0;
    console.log('Availability check result:', {
      isAvailable,
      conflictingReservations: records.length
    });
    return isAvailable;
  } catch (error) {
    console.error('Error checking availability:', error);
    console.error('Error details:', {
      message: error.message,
      error: error.error,
      statusCode: error.statusCode,
      dates: { startDate, endDate }
    });
    throw error;
  }
};

export const fetchGarments = async () => {
  try {
    const base = initAirtable();
    const records = await base('Garments')
      .select({
        view: 'Grid view'
      })
      .all();

    return records.map(record => ({
      id: record.id,
      ...record.fields
    }));
  } catch (error) {
    console.error('Error fetching garments:', error);
    throw error;
  }
};

export const checkGarmentAvailability = async (garmentIds, startDate, endDate) => {
  try {
    console.log('Checking garment availability:', { garmentIds, startDate, endDate });
    const base = initAirtable();
    const records = await base('Reservations').select({
      filterByFormula: `AND(
        {Start Date} <= '${endDate}',
        {End Date} >= '${startDate}',
        OR(${garmentIds.map(id => `SEARCH('${id}', ARRAYJOIN(Garments, ',')) > 0`).join(',')})
      )`
    }).all();

    const conflictingGarmentIds = [];
    records.forEach(record => {
      const reservedGarments = record.fields.Garments || [];
      reservedGarments.forEach(garment => {
        if (garmentIds.includes(garment)) {
          conflictingGarmentIds.push(garment);
        }
      });
    });

    const availableGarmentIds = garmentIds.filter(id => !conflictingGarmentIds.includes(id));

    console.log('Availability check result:', {
      availableGarmentIds,
      conflictingGarmentIds
    });

    return {
      availableGarmentIds,
      conflictingGarmentIds
    };
  } catch (error) {
    console.error('Error checking garment availability:', error);
    throw error;
  }
};

export const fetchCustomers = async () => {
  try {
    const base = initAirtable();
    const records = await base('Customers')
      .select({
        view: 'Grid view',
        sort: [{ field: 'Name', direction: 'asc' }]
      })
      .all();

    return records.map(record => ({
      id: record.id,
      ...record.fields
    }));
  } catch (error) {
    console.error('Error fetching customers:', error);
    throw error;
  }
};

export const createCustomer = async (customerData) => {
  try {
    console.log('Creating customer:', customerData);
    const record = await base('tbldOCm01wrCzPSRh').create([
      { fields: customerData }
    ]);
    console.log('Successfully created customer:', record[0].id);
    return record[0];
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};

export const isGarmentAvailableForDates = (garment, startDate, endDate, allBookings) => {
  if (!startDate || !endDate || !garment?.id) {
    console.log('Missing required data for availability check');
    return true;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Filter bookings for this garment
  const garmentBookings = allBookings.filter(booking =>
    booking.garment === garment.id &&
    booking.status !== 'Cancelled'
  );

  const isAvailable = !garmentBookings.some(booking => {
    if (!booking.startDate || !booking.endDate) return false;

    const bookingStart = new Date(booking.startDate);
    const bookingEnd = new Date(booking.endDate);

    return (start <= bookingEnd && end >= bookingStart);
  });

  return isAvailable;
};

export const fetchBookings = async (includeExpired = false) => {
  try {
    const base = initAirtable();
    const bookingsResponse = await base('Bookings')
      .select({
        view: 'Grid view',
        sort: [{ field: 'Start Date', direction: 'desc' }],
        filterByFormula: includeExpired
          ? "{Status} != 'Cancelled'"
          : "AND({Status} != 'Cancelled', {End Date} >= TODAY())",
        fields: [
          'Name (Customer)',
          'Email (Customer)',
          'Address (Customer)',
          'Start Date',
          'End Date',
          'Garment',
          'Rental periods',
          'Discount Rate',
          'SUBTOTAL',
          'TOTAL',
          'Rental agreement'
        ]
      })
      .all();

    // Fetch all garments for the bookings
    const garmentIds = new Set(
      bookingsResponse.flatMap(booking => booking.fields.Garment || [])
    );

    const garmentsResponse = await base('Garments')
      .select({
        filterByFormula: `OR(${Array.from(garmentIds)
          .map(id => `RECORD_ID()='${id}'`)
          .join(',')})`,
        fields: ['Item Name', 'Image']
      })
      .all();

    // Create a map of garment details for quick lookup
    const garmentDetails = new Map(
      garmentsResponse.map(garment => [garment.id, garment.fields])
    );

    // Add garment details to each booking
    const bookingsWithDetails = bookingsResponse.map(booking => ({
      id: booking.id,
      ...booking.fields,
      Garments: (booking.fields.Garment || []).map(garmentId => ({
        id: garmentId,
        ...garmentDetails.get(garmentId)
      }))
    }));

    return bookingsWithDetails;
  } catch (error) {
    console.error('Error fetching bookings:', error);
    throw error;
  }
};

// For backward compatibility
export const fetchAllBookings = () => fetchBookings(true);
export const fetchCurrentBookings = () => fetchBookings(false);

export const deleteBooking = async (bookingId) => {
  try {
    console.log('Deleting booking:', bookingId);
    await base(TABLES.BOOKINGS).destroy(bookingId);
    console.log('Successfully deleted booking:', bookingId);
    return true;
  } catch (error) {
    console.error('Error deleting booking:', error);
    throw error;
  }
};

export const updateCustomer = async (customerId, customerData) => {
  try {
    const response = await fetch(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_CUSTOMERS_TABLE_ID}/${customerId}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields: customerData
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update customer');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating customer:', error);
    throw error;
  }
};

export const deleteCustomer = async (customerId) => {
  try {
    const response = await fetch(`https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/${process.env.REACT_APP_AIRTABLE_CUSTOMERS_TABLE_ID}/${customerId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete customer');
    }

    return true;
  } catch (error) {
    console.error('Error deleting customer:', error);
    throw error;
  }
};

export default base;
const API_KEY = process.env.REACT_APP_DOCS_AUTOMATOR_API_KEY;
const DOCS_AUTOMATOR_DOC_ID = process.env.REACT_APP_DOCS_AUTOMATOR_DOC_ID;

export const createRentalAgreement = async (bookingId) => {
  try {
    console.log('Creating rental agreement for booking:', bookingId);

    const response = await fetch('https://api.docsautomator.co/createDocument', {
      method: 'post',
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        recId: bookingId,
        docId: DOCS_AUTOMATOR_DOC_ID,
        async: false
      })
    });

    console.log('Raw response:', response);
    const data = await response.json();
    console.log('Response data:', data);

    if (!response.ok) {
      console.error('DocsAutomator error response:', data);
      throw new Error(data.message || 'Failed to create rental agreement');
    }

    return {
      pdfUrl: data.pdfUrl,
      googleDocUrl: data.googleDocUrl
    };
  } catch (error) {
    console.error('Error creating rental agreement:', error);
    throw error;
  }
};
const hostname = window.location.hostname;
const isLocalhost = hostname === 'localhost' || hostname === 'rent.localhost';

export const config = {
  isRentalSubdomain: hostname.startsWith('rent.'),
  rentalDomain: isLocalhost ? 'rent.localhost:3000' : 'rent.elodiecarstensen.com',
  mainDomain: isLocalhost ? 'localhost:3000' : 'elodiecarstensen.com',
};

export const routes = {
  rental: {
    home: '/',
    login: '/login',
    makeReservation: '/make-reservation',
    createAgreement: '/create-agreement',
  },
  main: {
    home: '/',
    gallery: '/gallery',
    about: '/about',
    rental: '/rental',
  }
};

// Helper function to get the correct route based on subdomain
export const getRoute = (routeName) => {
  if (config.isRentalSubdomain) {
    return routes.rental[routeName] || '/';
  }
  return routes.main[routeName] || '/';
};

// Helper function to get full URL for rental subdomain
export const getRentalUrl = (path = '') => {
  return `https://${config.rentalDomain}${path}`;
};
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createRentalAgreement } from '../../utils/docsautomator';
import './RentalFeatures.scss';

const CreateAgreement = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const handleCreateAgreement = async () => {
    try {
      setLoading(true);
      setError('');

      const { pdfUrl } = await createRentalAgreement(bookingId);

      setPdfUrl(pdfUrl);
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      console.error('Error creating agreement:', err);
      setError(err.message || 'Failed to create rental agreement');
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/rental');
  };

  if (success && pdfUrl) {
    return (
      <div className="rental-feature-page">
        <header className="feature-header">
          <div className="header-left">
            <button onClick={handleBack} className="back-button">← Back</button>
            <h1>Rental Agreement</h1>
          </div>
        </header>

        <div className="feature-content">
          <div className="success-message">
            <h2>Agreement Created Successfully!</h2>
            <div className="success-actions">
              <button
                className="button"
                onClick={() => window.open(pdfUrl, '_blank')}
              >
                Download Agreement
              </button>
              <button
                className="button secondary"
                onClick={handleBack}
              >
                Return to Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="rental-feature-page">
      <header className="feature-header">
        <div className="header-left">
          <button onClick={handleBack} className="back-button">← Back</button>
          <h1>Create Rental Agreement</h1>
        </div>
      </header>

      <div className="feature-content">
        <div className="form-container">
          {error && <div className="error-message">{error}</div>}

          <button
            className="button"
            onClick={handleCreateAgreement}
            disabled={loading}
          >
            {loading ? 'Creating Agreement...' : 'Create Agreement'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAgreement;